// @see: https://chakra-ui.com/docs/navigation/link
const buttonLinkBaseStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  px: { base: 4, md: 6 },
  py: 3,
  fontSize: 'md',
  backgroundColor: 'kelsonBlue.800',
  textColor: 'kelsonGrayscale.50',
  _hover: {
    textDecoration: 'none',
    backgroundColor: 'kelsonBlue.700',
    textColor: 'kelsonGrayscale.50',
  },
}

export const Link = {
    baseStyle: {
        fontWeight: 700,
        textColor: 'kelsonGreen.500',
        transition: '0.3s all',
        _hover: {
            textDecoration: 'none',
            textColor: 'kelsonGreen.600',
        },
        _focus: {
            outline: 'none',
        },
    },
    // Note: when adding variants to this list, add a constant with the variant
    // name to ../../inc/constants, and then use the constant as the link
    // variant prop.
    variants: {
        button: {
            ...buttonLinkBaseStyle
        },
        buttonLight: {
          ...buttonLinkBaseStyle,
          backgroundColor: 'kelsonGreen.200',
          textColor: 'kelsonGrayscale.900',
          _hover: {
            backgroundColor: 'kelsonGreen.100',
            textColor: 'kelsonGrayscale.800',
          },
        },
        buttonOutline: {
          ...buttonLinkBaseStyle,
          background: 'transparent',
          borderWidth: 1,
          borderColor: 'kelsonGreen.200',
          textColor: 'kelsonGreen.200',
          _hover: {
            backgroundColor: 'kelsonGreen.50',
            textColor: 'kelsonGreen.600',
          },
        },
        // sleight: {
        //   fontWeight: 'regular'
        // },
        inherit: {
            textColor: 'inherit',
            fontFamily: 'inherit',
            _hover: {
                textColor: 'inherit',
                textDecoration: 'inherit',
            },
        },
        navLink: {
            textColor: 'kelsonBlue.500',
            fontWeight: 700,
            _hover: {
                textColor: 'kelsonBlue.600',
                textDecoration: 'none',
            },
        },
    },
}
