// @see: https://chakra-ui.com/docs/theming/theme#colors
export const colors = {
    kelsonGrayscale: {
        50: '#FFFFFA',
        100: '#E7E7E2',
        200: '#CECECA',
        300: '#B6B6B3',
        400: '#9E9E9B',
        500: '#858583',
        600: '#6D6D6B',
        700: '#555554',
        800: '#3C3C3C',
        900: '#242424',
    },
    kelsonGold: {
        50: '#fef9e7',
        100: '#fcf0c4',
        200: '#fbe79d',
        300: '#f9dd75',
        400: '#f7d558',
        500: '#f6ce3a',
        600: '#f5c934',
        700: '#f3c22c',
        800: '#f2bc25',
        900: '#efb018',
    },
    kelsonRed: {
        50: '#fbece9',
        100: '#f5d1c8',
        200: '#efb2a4',
        300: '#e89380',
        400: '#e37b64',
        500: '#de6449',
        600: '#da5c42',
        700: '#d55239',
        800: '#d14831',
        900: '#c83621',
    },
    kelsonGreen: {
        50: '#e0fcf4',
        100: '#c0ede2',
        200: '#9de0d1',
        300: '#79d3c2',
        400: '#55c6b4',
        500: '#3dad9f',
        600: '#287a73' /* #logo */,
        700: '#1c6057',
        800: '#0a3b30',
        900: '#00150f',
    },
    kelsonLightGreen: {
        50: '#e7fcf9',
        100: '#c2f8ef',
        200: '#9af3e5',
        300: '#71eedb',
        400: '#52ead3',
        500: '#34e6cb',
        600: '#2fe3c6',
        700: '#27dfbe',
        800: '#21dbb8',
        900: '#15d5ac',
    },
    kelsonBlueGreen: {
        50: '#287A73' /* #logo */,
        100: '#26716C',
        200: '#236766',
        300: '#215E5F',
        400: '#1E5459',
        500: '#1C4B52',
        600: '#19414C',
        700: '#173845',
        800: '#142E3F',
        900: '#122538' /* #logo */,
    },
    kelsonBlue: {
        50: '#e8f8fe',
        100: '#c5e2ec',
        200: '#a1cbdd',
        300: '#7cb4d0',
        400: '#589ac2',
        500: '#407da9',
        600: '#315e83',
        700: '#23425e',
        800: '#122538' /* #logo */,
        900: '#010f17',
    },
}
