import { HEADING_VARIANT_PAGE_TITLE } from '../../../inc/constants'

export const Heading = {
    baseStyle: {
        fontFamily: 'fonts.heading',
        fontWeight: 400,
        lineHeight: '1.5em',
    },
    // Note: when adding variants to this list, add a constant with the variant
    // name to ../../inc/constants, and then use the constant as the link
    // variant prop.
    variants: {
        [HEADING_VARIANT_PAGE_TITLE]: {
            textColor: 'kelsonGrayscale.50',
            maxWidth: 'container.lg',
            textAlign: { base: 'center', lg: 'right' },
            fontWeight: 520,
            lineHeight: '1.2em',
            fontStyle: 'normal',
            textShadow: '0 0 10px #142E3F',
            backdropFilter: 'blur(1px)',
            p: 4,
            textTransform: 'none',
            fontSize: { base: '5xl', md: '7xl', '2xl': '8xl' },
        },
        pageTitleAlt: {
            textColor: 'kelsonGrayscale.800',
            textTransform: 'none',
            fontStyle: 'normal',
            maxWidth: 'container.xs',
            px: 8,
            mb: 32,
            textAlign: { base: 'center', lg: 'right' },
            fontSize: { base: '2xl', lg: '4xl' },
            lineHeight: '1.5em',
        },
        blockHeading: {
            mb: 4,
        },
        blockSubheading: {
            fontStyle: 'italic',
            fontWeight: 'normal',
        },
    },
}
