// @todo: Add explicit entries to this list as needed after trying to use built-in Chakra theme sizes where possible.
// @todo: Consider importing all Chakra default theme values here so devs can quickly reference them, and benefit from IDE autocomplete. If doing so, clearly differentiate between default and custom values.
// @see: https://chakra-ui.com/docs/theming/theme#sizes
export const sizes = {
    // General sizes:
    // e.g.: "9xl": "100rem",

    // Container sizes:
    container: {
        xxs: '350px',
        xs: '500px',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1440px',
        '3xl': '1800px',
    },

    lineHeights: {
        11: '2rem',
    },
}
