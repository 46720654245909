import { extendTheme } from '@chakra-ui/react'
import { List } from './core/List'
import { Link } from './core/Link'
import { colors } from './foundations/colors'
import { sizes } from './foundations/sizes'
import { typography } from './foundations/typography'
import { Heading } from './core/Heading'
import { Button } from './core/Button'

const fallbackFontsSansSerif =
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
const fallbackFontsSerif =
    '"Apple Garamond", "Baskerville", "Times New Roman", "Droid Serif", "Times","Source Serif Pro", serif'

const extensions = {
    // ...globalThemeStyles,
    fonts: {
        heading: `EB Garamond Variable, ${fallbackFontsSerif}`,
        headingSans: `Inter Variable, ${fallbackFontsSansSerif}`,
        bold: `EB Garamond Variable, ${fallbackFontsSerif}`,
        body: `EB Garamond Variable, ${fallbackFontsSerif}`,
    },
    // Foundational overrides.
    sizes,
    // space: spacing,
    colors,
    // shadows: shadows,
    ...typography,
    // // Component overrides.
    components: {
        List,
        Heading,
        Link,
        Button,
        // Divider: Divider,
        // Modal: Modal,
    },
    // direction,
    // config,
    styles: {
        global: {
            body: {
                fontSize: 'xl',
                background: 'kelsonGrayscale.50',
            },
        },
    },
}

export default extendTheme(extensions)
