export const LIST_VARIANT_BULLETPROOF = 'bulletproof'
export const HEADING_VARIANT_PAGE_TITLE = 'pageTitle'
export const HEADING_VARIANT_PAGE_TITLE_ALT = 'pageTitleAlt'
export const HEADING_VARIANT_BLOCK_HEADING = 'blockHeading'
export const HEADING_VARIANT_BLOCK_SUBHEADING = 'blockSubheading'
export const LINK_VARIANT_BUTTON = 'button'
export const LINK_VARIANT_BUTTON_LIGHT = 'buttonLight'
export const LINK_VARIANT_BUTTON_OUTLINE = 'buttonOutline'
export const BLOCK_VARIANT_EDGE_TO_EDGE = 'edgeToEdge'
export const BLOCK_VARIANT_FULLSCREEN = 'fullscreen'
export const BLOCK_VARIANT_MINIMAL = 'minimal'
export const BUTTON_VARIANT_CLOSE = 'close'
export const GLOBAL_HEADER_HEIGHT = '4rem'
export const MAPBOX_MIN_ZOOM = 1
export const MAPBOX_MAX_ZOOM = 12
export const MAPBOX_DEFAULT_ZOOM = 2
export const KELSON_COORDINATES = [-70.25370698229875, 43.65132857423392]
export const KELSON_DEFAULT_ZOOM = 6
export const KELSON_MIN_ZOOM = 2
export const KELSON_MAX_ZOOM = 15
