export const ListConstants = {
    LIST_VARIANT_BULLETPROOF: 'bulletproof',
    LIST_OL_NUMBERLESS: 'orderedNumberless',
    LIST_UL_BULLETED: 'bulleted',
    LIST_OL_NUMBERED: 'numbered',
}

const { LIST_VARIANT_BULLETPROOF, LIST_OL_NUMBERLESS, LIST_UL_BULLETED, LIST_OL_NUMBERED } = ListConstants

export const List = {
    // parts: ["container", "item", "icon"],
    // baseStyle: {
    // },
    // Note: when adding variants to this list, add a constant with the variant
    // name to ../../inc/constants, and then use the constant as the link
    // variant prop.
    variants: {
        [LIST_VARIANT_BULLETPROOF]: {
            container: {
                display: 'block',
                listStyleType: 'none',
            },
            item: {
                display: 'block',
            },
        },
        [LIST_OL_NUMBERLESS]: {
            container: {
                listStyleType: 'none',
                margin: 0,
            },
            item: {
                margin: 0,
                mb: 1.5,
            },
        },
        [LIST_OL_NUMBERED]: {
            container: {
                margin: 0,
            },
            item: {
                listStyleType: 'decimal',
                margin: 0,
                ml: 4,
                mb: 4,
            },
        },
        [LIST_UL_BULLETED]: {
            container: {
                margin: 0,
                pl: 8,
            },
            item: {
                listStyleType: 'disc',
                margin: 0,
                ml: 4,
                mb: 4,
            },
        },
    },
}
