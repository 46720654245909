
export const Button = {
  // Note: when adding variants to this list, add a constant with the variant
  // name to ../../inc/constants, and then use the constant as the link
  // variant prop.
  variants: {
    close: {
      position: 'absolute',
      top: 0,
      right: 0,
      fontSize: '2xl',
      fontWeight: 'bold',
    }
  }
}
